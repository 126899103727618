var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main"},[_c('el-dialog',{attrs:{"title":"查看信用评价报告","visible":_vm.creditEvaluationFlag,"close-on-press-escape":false,"close-on-click-modal":false,"modal-append-to-body":false,"modal":true,"top":"10vh","width":"60%"},on:{"close":_vm.closeCreditEvaluation}},[_c('div',{staticClass:"creditEvaluationBox"},[_c('div',{staticClass:"creditrating-box"},[_c('div',{staticClass:"info-title"},[_vm._v("个人评价信用报告")]),_c('div',{staticClass:"credit-evi"},[_c('div',{staticClass:"evi-box",on:{"click":function($event){return _vm.toWinding()}}},[_c('i',{staticClass:"el-icon-document",staticStyle:{"font-size":"20px"}}),_c('span',{staticClass:"solid"},[_vm._v("|")]),_vm._v(" 信用存证 ")])]),_c('div',{staticClass:"creditrating-min"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"left-head"},[_c('img',{staticClass:"img",attrs:{"src":_vm.item.avatar ? _vm.$imgUrl(_vm.item.avatar) : _vm.srcImgUrl,"onerror":_vm.$global.srcImgUrl,"alt":""}}),_c('div',{staticClass:"head-info"},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm.obj.name))]),_c('div',{staticClass:"grade"},[_c('span',[_vm._v(" 信用分: ")]),(_vm.item.grade >= 80)?_c('span',{staticClass:"score_text",staticStyle:{"font-size":"28px"},style:({
                      color: _vm.gradeColor[0],
                      borderColor: _vm.gradeColor[0],
                    })},[_vm._v(" "+_vm._s(Number(_vm.item.grade).toFixed(1))+" ")]):_vm._e(),(_vm.item.grade < 80 && _vm.item.grade >= 60)?_c('span',{staticClass:"score_text",staticStyle:{"font-size":"28px"},style:({
                      color: _vm.gradeColor[1],
                      borderColor: _vm.gradeColor[1],
                    })},[_vm._v(" "+_vm._s(Number(_vm.item.grade).toFixed(1))+" ")]):_vm._e(),(_vm.item.grade < 60 && _vm.item.grade >= 40)?_c('span',{staticClass:"score_text",staticStyle:{"font-size":"28px"},style:({
                      color: _vm.gradeColor[2],
                      borderColor: _vm.gradeColor[2],
                    })},[_vm._v(" "+_vm._s(Number(_vm.item.grade).toFixed(1))+" ")]):_vm._e(),(_vm.item.grade < 40)?_c('span',{staticClass:"score_text",staticStyle:{"font-size":"28px"},style:({
                      color: _vm.gradeColor[3],
                      borderColor: _vm.gradeColor[3],
                    })},[_vm._v(" "+_vm._s(Number(_vm.item.grade).toFixed(1))+" ")]):_vm._e(),_c('span',[_vm._v("分")])])])]),_c('div',{staticClass:"work-info"},[_vm._v(" "+_vm._s(_vm.obj.sex == '2' ? '女' : '男')+" "),_c('span',[_vm._v("|")]),_vm._v(" "+_vm._s(_vm.obj.age)+"岁 "),_c('span',[_vm._v("|")]),_vm._v(" "+_vm._s(_vm.obj.nation ? _vm.obj.nation : '汉')+" "),(_vm.obj.edu)?_c('span',[_vm._v("|")]):_vm._e(),_vm._v(" "+_vm._s(_vm.obj.edu ? _vm.getPostEdu(_vm.obj.edu) : '')+" ")])]),_c('div',{staticClass:"right"},[_c('h2',{staticStyle:{"text-align":"center"}},[_vm._v("基本评价")]),_c('biantu',{ref:"biantu"}),_c('h2',{staticStyle:{"text-align":"center","margin-top":"45px"}},[_vm._v("信用评价")]),_c('bian',{ref:"bian"})],1)])])]),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.closeCreditEvaluation}},[_vm._v("关闭")])],1)]),_c('el-dialog',{staticClass:"dialog1",attrs:{"width":"900px","visible":_vm.outerVisible,"custom-class":"previewDialog","modal-append-to-body":false},on:{"update:visible":function($event){_vm.outerVisible=$event}}},[_c('div',{staticClass:"create-info"},[_c('div',{staticClass:"user-info"},[_c('div',{staticClass:"logo-title"},[_c('img',{staticClass:"logo-img",attrs:{"src":require("@/assets/home/logo.png"),"alt":""}}),_c('div',{staticClass:"logo-text"},[_vm._v("甄工匠")])]),_c('div',{staticClass:"title-name"},[_vm._v("信用评价存证信息")]),_c('div',{staticClass:"left"},[_c('p',{staticClass:"user-name"},[_vm._v(_vm._s(_vm.obj.name))]),_c('div',{staticClass:"info-data"},[_vm._v(" "+_vm._s(_vm.obj.sex == '2' ? '女' : '男')+" "),_c('span',[_vm._v("|")]),_vm._v(" "+_vm._s(_vm.obj.age)+"岁 "),_c('span',[_vm._v("|")]),_vm._v(" "+_vm._s(_vm.$occlusionCard(_vm.obj.idCard))+" ")])]),_c('div',{staticClass:"right"},[_c('div',{staticClass:"grade-title"},[_vm._v("信用评价总得分")]),(_vm.item.grade >= 80)?_c('p',{staticClass:"grade",style:({
              color: _vm.gradeColor[0],
              borderColor: _vm.gradeColor[0],
            })},[_vm._v(" "+_vm._s(Number(_vm.item.grade).toFixed(1))+" ")]):_vm._e(),(_vm.item.grade < 80 && _vm.item.grade >= 60)?_c('p',{staticClass:"grade",style:({
              color: _vm.gradeColor[1],
              borderColor: _vm.gradeColor[1],
            })},[_vm._v(" "+_vm._s(Number(_vm.item.grade).toFixed(1))+" ")]):_vm._e(),(_vm.item.grade < 60 && _vm.item.grade >= 40)?_c('p',{staticClass:"grade",style:({
              color: _vm.gradeColor[2],
              borderColor: _vm.gradeColor[2],
            })},[_vm._v(" "+_vm._s(Number(_vm.item.grade).toFixed(1))+" ")]):_vm._e(),(_vm.item.grade < 40)?_c('p',{staticClass:"grade",style:({
              color: _vm.gradeColor[3],
              borderColor: _vm.gradeColor[3],
            })},[_vm._v(" "+_vm._s(Number(_vm.item.grade).toFixed(1))+" ")]):_vm._e()])]),_c('div',{staticClass:"detail-info"},[_c('div',{staticClass:"title-name"},[_vm._v("上链详情")]),_vm._l((_vm.data),function(item,index){return _c('div',{key:index,staticClass:"up-info"},[_c('div',{staticClass:"left"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"right"},[_vm._v(_vm._s(item.info))])])})],2)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }