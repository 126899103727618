<template>
  <div class="main">
    <el-dialog
      title="查看简历"
      :visible="seeFlag"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      @close="cancle"
      :modal="true"
      top="10vh"
      width="60%"
    >
      <div class="itemBox">

        <div class="essentialInformation">
          <div class="title">
            <div class="blueBgc">
              <i
                class="iconfont icon-jibenxinxi1"
                style="font-size: 20px; color: #fff"
              ></i>
            </div>
            <b>基本信息</b>
            <el-divider></el-divider>
          </div>
          <div class="formItem">
            <div class="personalInfo">
              <b>{{ resume.name }}</b>
              <ul>
                <li>
                  <span class="fontColor6">出生年月</span>
                  <span class="fontColor6">{{
                    resume.wasBornDate
                      ? resume.wasBornDate.substring(
                          0,
                          resume.wasBornDate.indexOf(' ')
                        )
                      : ''
                  }}</span>
                </li>
                <li>
                  <span class="fontColor6">民族</span>
                  <span class="fontColor6">{{ resume.nation }}</span>
                </li>
                <li>
                  <span class="fontColor6">年龄</span>
                  <span class="fontColor6">{{ resume.age }}</span>
                </li>
                <li>
                  <span class="fontColor6">联系方式</span>
                  <span class="fontColor6">{{ resume.tel }}</span>
                  <!-- <span class="fontColor6" v-if="subEquity">{{ resume.tel }}</span> -->
                  <!-- <span class="fontColor6" v-else>{{ resume.tel.replace(resume.tel.substring(3,7),'****') }}</span> -->
                </li>
                <li>
                  <span class="fontColor6">学历</span>
                  <span class="fontColor6">{{ resume.edu ? showEdu(resume.edu) : '' }}</span>
                </li>
                <li>
                  <span class="fontColor6">邮箱</span>
                  <span class="fontColor6">{{ resume.email }}</span>
                </li>
                <li>
                  <span class="fontColor6">毕业时间</span>
                  <span class="fontColor6">{{
                    resume.finishSchoolTime
                      ? resume.finishSchoolTime.substring(
                          0,
                          resume.finishSchoolTime.indexOf(' ')
                        )
                      : ''
                  }}</span>
                </li>
                <li>
                  <span class="fontColor6">毕业学校</span>
                  <span class="fontColor6">{{ resume.school }}</span>
                </li>
                <li>
                  <span class="fontColor6">工作经验</span>
                  <span class="fontColor6">{{ getExp(resume.exp) }}</span>
                </li>
                <li>
                  <span class="fontColor6">在职状态</span>
                  <span class="fontColor6">{{ getWorkingState(resume.workingState) }}</span>
                </li>
              </ul>
            </div>
            <div class="photo">
              <img class="photo" :src="resume.avatar" :onerror="$global.srcImgUrl" alt="" v-if="resume.avatar" />
              <img
                class="photo"
                src="@/assets/personCenter/resumeManagement/null.png"
                alt=""
                v-else
              />
            </div>
          </div>
        </div>

        <div class="jobSearchIntention">
          <div class="title">
            <div class="blueBgc">
              <i
                class="iconfont icon-lingdaitie2"
                style="font-size: 20px; color: #fff"
              ></i>
            </div>
            <b>求职意向</b>
            <el-divider></el-divider>
          </div>
          <div class="formItem">
            <div class="jobSearchIntentionInfo">
              <div class="infoItem fontColor6">
                <i class="iconfont icon-zhiye1" style="font-size: 20px"></i>
                <span>{{ resume.expectPost }}</span>
              </div>
              <el-divider direction="vertical"></el-divider>
              <div class="infoItem fontColor6">
                <i class="iconfont icon-xinzi" style="font-size: 18px"></i>
                <span>{{ resume.expectSalary }}</span>
              </div>
              <el-divider direction="vertical"></el-divider>
              <div class="infoItem fontColor6">
                <i class="iconfont icon-hangye" style="font-size: 20px"></i>
                <span>{{ showTradeType(this.tradeTypeList, resume.expectIndustry) }}</span>
              </div>
              <el-divider direction="vertical"></el-divider>
              <div class="infoItem fontColor6">
                <i class="iconfont icon-31dingwei" style="font-size: 20px"></i>
                <span>{{ resume.city }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="workExperience">
          <div class="title">
            <div class="blueBgc">
              <i
                class="iconfont icon-xiangmujingli-02"
                style="font-size: 20px; color: #fff"
              ></i>
            </div>
            <b>工作经历</b>
            <el-divider></el-divider>
          </div>
          <div
            class="formItem"
            v-for="(item, index) in workExperience"
            :key="index"
          >
            <div class="formItemLeft">
              <p>{{ item.postName }}</p>
              <span class="fontColor6 fontSize14"
                >{{
                  item.btime
                    ? item.btime.substring(0, item.btime.lastIndexOf('-'))
                    : ''
                }}{{ item.btime ? ' - ' : ''
                }}{{
                  item.ntime
                    ? item.ntime.substring(0, item.ntime.lastIndexOf('-'))
                    : ''
                }}</span
              >
            </div>
            <div class="formItemRight">
              <p class="companyName">{{ item.title }}</p>
              <p class="workInfo fontColor6 fontSize14">{{ item.content }}</p>
            </div>
          </div>
        </div>

        <div class="projectExperience">
          <div class="title">
            <div class="blueBgc">
              <i
                class="iconfont icon-jiaoyu1"
                style="font-size: 20px; color: #fff"
              ></i>
            </div>
            <b>项目经历</b>
            <el-divider></el-divider>
          </div>
          <div
            class="formItem"
            v-for="(item, index) in projectExperience"
            :key="index"
          >
            <p style="font-size: 18px">{{ item.title }}</p>
            <p class="fontSize14">
              工作时间：{{
                item.btime
                  ? item.btime.substring(0, item.btime.lastIndexOf('-'))
                  : ''
              }}{{ item.btime ? ' - ' : ''
              }}{{
                item.ntime
                  ? item.ntime.substring(0, item.ntime.lastIndexOf('-'))
                  : ''
              }}
            </p>
            <p>
              工作内容：<span class="fontColor6 fontSize14">{{
                item.content
              }}</span>
            </p>
          </div>
        </div>

        <div class="educationalExperience">
          <div class="title">
            <div class="blueBgc">
              <i
                class="iconfont icon-jiaoyu1"
                style="font-size: 20px; color: #fff"
              ></i>
            </div>
            <b>教育经历</b>
            <el-divider></el-divider>
          </div>
          <div
            class="formItem"
            v-for="(item, index) in educationalExperience"
            :key="index"
          >
            <div class="formItemLeft">
              <p>{{ item.specialty }}</p>
              <span class="fontColor6 fontSize14"
                >{{
                  item.btime
                    ? item.btime.substring(0, item.btime.lastIndexOf('-'))
                    : ''
                }}{{ item.btime ? ' - ' : ''
                }}{{
                  item.ntime
                    ? item.ntime.substring(0, item.ntime.lastIndexOf('-'))
                    : ''
                }}</span
              >
            </div>
            <div class="formItemRight">
              <p class="schoolName">{{ item.title }}</p>
              <p class="workInfo fontColor6 fontSize14">{{ item.content }}</p>
            </div>
          </div>
        </div>

        <div class="trainingExperience">
          <div class="title">
            <div class="blueBgc">
              <i
                class="iconfont icon-jiaoyu1"
                style="font-size: 20px; color: #fff"
              ></i>
            </div>
            <b>培训经历</b>
            <el-divider></el-divider>
          </div>
          <div
            class="formItem"
            v-for="(item, index) in trainingExperience"
            :key="index"
          >
            <p style="font-size: 18px">{{ item.title }}</p>
            <p class="fontColor6 fontSize14">
              {{
                item.btime
                  ? item.btime.substring(0, item.btime.lastIndexOf('-'))
                  : ''
              }}{{ item.btime ? ' - ' : ''
              }}{{
                item.ntime
                  ? item.ntime.substring(0, item.ntime.lastIndexOf('-'))
                  : ''
              }}
            </p>
            <p class="fontColor6 fontSize14">{{ item.content }}</p>
          </div>
        </div>

        <div class="selfEvaluation">
          <div class="title">
            <div class="blueBgc">
              <i
                class="iconfont icon-pingjia"
                style="font-size: 20px; color: #fff"
              ></i>
            </div>
            <b>自我评价</b>
            <el-divider></el-divider>
          </div>
          <div class="formItem">
            <span class="fontColor6 fontSize14">{{ resume.myIntro }}</span>
          </div>
        </div>

        <!-- <div class="skillCertificate">
          <div class="title">
            <div class="blueBgc">
              <i
                class="iconfont icon-pingjia"
                style="font-size: 20px; color: #fff"
              ></i>
            </div>
            <b>技能证书</b>
            <el-divider></el-divider>
          </div>
          <div class="formItem">
            <div class="imgBox">
              <span>学历证书</span>
              <img
                v-if="certificates.degree_certificate"
                :src="
                  $global.baseUrl + imgUrl + certificates.degree_certificate
                "
                alt=""
              />
              <img v-else src="@/assets/img/null.png" alt="" />
            </div>
            <div class="imgBox">
              <span>资格证书</span>
              <img
                v-if="certificates.qualification_certificate"
                :src="
                  $global.baseUrl +
                  imgUrl +
                  certificates.qualification_certificate
                "
                alt=""
              />
              <img v-else src="@/assets/img/null.png" alt="" />
            </div>
            <div class="imgBox">
              <span>职称证书</span>
              <img
                v-if="certificates.title_certificate"
                :src="$global.baseUrl + imgUrl + certificates.title_certificate"
                alt=""
              />
              <img v-else src="@/assets/img/null.png" alt="" />
            </div>
          </div>
        </div> -->
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="cancle">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { jobPostType } from '@/api/position/position'
export default {
  name: '',
  data() {
    return {
      imgPath: '',
      dict: [],
      jobEdu: [],
      exp: [],
      workingState: [],
      jobPay: [],
      tradeTypeList: [], //行业列表

      workExperience: [],
      educationalExperience: [],
      projectExperience: [],
      trainingExperience: []
    }
  },
  watch: {},
  props: {
    seeFlag: Boolean,
    resume: Object,
    subEquity: Boolean
  },
  components: {},
  computed: {},
  created() {
    this.init()
    this.getJobTradeType()
    console.log(this.resume,'this.resume created');
    let items = this.resume.items
    items.forEach(item => {
      if (item.itemType === '1') {
        // 工作经历
        this.workExperience.push(item)
      } else if (item.itemType === '2') {
        // 教育经历
        this.educationalExperience.push(item)
      } else if (item.itemType === '3') {
        // 项目经历
        this.projectExperience.push(item)
      } else if (item.itemType === '4') {
        // 培训经历
        this.trainingExperience.push(item)
      }
    })
    console.log(this.workExperience,'workExperience');
  },
  mounted() {},
  methods: {
    init() {
      this.dict = JSON.parse(localStorage.getItem('dict'))
this.jobEdu = this.dict.filter(i => i.code == 'job_edu')[0].children
this.exp = this.dict.filter(i => i.code == 'job_exp')[0].children
this.workingState = this.dict.filter(
      i => i.code == 'job_resume_working_state'
    )[0].children
    this.jobPay = this.dict.filter(i => i.code == 'job_pay')[0].children
    },
    cancle() {
      this.$emit('change', false)
    },
    //行业类型
    getJobTradeType() {
      jobPostType({ code: 'trade_type' }).then(res => {
        this.tradeTypeList = res.data[0].children
      })
    },
    // 显示学历
    showEdu(val) {
      return this.jobEdu.filter(i => i.code == val)[0].name
    },
    // 显示工作经验
    getExp(val) {
      return this.exp.filter(i => i.code == val)[0].name
    },
    // 显示在职状态
    getWorkingState(val) {
      return this.workingState.filter(i => i.code == val)[0].name
    },
    // 显示薪资
    getPay(code) {
      return this.jobPay.filter(i => i.code == code)[0].name
    },
    showTradeType(list, id) {
      var title = ''
      for (let i = 0; i < list.length; i++) {
        if (list[i].id == id) {
          title = list[i].title
          break
        } else if (list[i].children && list[i].children.length > 0) {
          title = this.showTradeType(list[i].children, id)
          if (title) return title
        }
      }
      console.log(title)
      return title
    },
  },
  filters: {},
}
</script>

<style scoped lang="scss">
.itemBox {
  padding: 0 20px;
  height: 63vh;
  overflow: auto;
}

.itemBox::-webkit-scrollbar {
  width: 4px;
}

.itemBox::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
}

.title {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  .blueBgc {
    width: 32px;
    height: 32px;
    text-align: center;
    line-height: 32px;
    background-color: #3f9efc;
    border-radius: 50%;
    margin-right: 20px;
  }
  b {
    font-size: 20px;
  }
  .el-divider {
    flex: 1;
    margin-left: 20px;
  }
}

.formItem {
  margin-left: 50px;
  margin-bottom: 30px;
}

.essentialInformation {
  .formItem {
    display: flex;
    .personalInfo {
      flex: 1;
      b {
        font-size: 28px;
      }
      ul {
        display: flex;
        flex-wrap: wrap;
        margin-top: 10px;
        li {
          padding: 10px 0;
          width: 50%;
          span:nth-child(1) {
            display: inline-block;
            width: 70px;
            text-align-last: justify;
            position: relative;
            margin-right: 20px;
          }
          span:nth-child(1)::after {
            position: absolute;
            content: ':';
            right: -6px;
          }
        }
      }
    }
    .photo {
      width: 150px;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 100%;
        height: 200px;
        object-fit: cover;
      }
      .editBtn {
        width: 58px;
        height: 24px;
        border: 1px solid #666;
        border-radius: 4px;
        text-align: center;
        line-height: 24px;
        margin-top: 20px;
        cursor: pointer;
      }
    }
  }
}

.jobSearchIntentionInfo {
  display: flex;
  align-items: center;
  .infoItem {
    display: flex;
    align-items: center;
    i {
      margin-right: 10px;
    }
  }
  .el-divider {
    margin: 0 20px;
  }
}

.workExperience,
.educationalExperience {
  .formItem {
    display: flex;
    padding-bottom: 20px;
    .formItemLeft {
      width: 190px;
      p {
        margin-bottom: 10px;
        font-size: 18px;
      }
    }
    .formItemRight {
      flex: 1;
      .companyName,
      .schoolName {
        font-size: 18px;
        margin-bottom: 10px;
      }
    }
  }
  .formItem:nth-last-child(1) {
    padding-bottom: 0;
  }
}

.trainingExperience,
.projectExperience {
  .formItem {
    padding-bottom: 20px;
    p {
      display: block;
      margin-bottom: 20px;
    }
  }
}

.skillCertificate {
  .formItem {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    .imgBox {
      display: flex;
      flex-direction: column;
      margin-right: 20px;
      margin-bottom: 10px;
      span {
        font-size: 14px;
        color: #666;
      }
      img {
        width: 140px;
        height: 88px;
        object-fit: contain;
        margin-top: 10px;
      }
    }
  }
}
</style>
