<template>
  <div class="main">
    <el-dialog
      title="查看信用评价报告"
      :visible="creditEvaluationFlag"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      @close="closeCreditEvaluation"
      :modal="true"
      top="10vh"
      width="60%"
    >
      <div class="creditEvaluationBox">
        <div class="creditrating-box">
          <div class="info-title">个人评价信用报告</div>
          <div class="credit-evi">
            <div class="evi-box" @click="toWinding()">
              <i class="el-icon-document" style="font-size: 20px"></i>
              <span class="solid">|</span>
              信用存证
            </div>
          </div>
          <div class="creditrating-min">
            <!-- 左边 -->
            <div class="left">
              <div class="left-head">
                <img
                  class="img"
                  :src="item.avatar ? $imgUrl(item.avatar) : srcImgUrl"
                  :onerror="$global.srcImgUrl"
                  alt=""
                />
                <div class="head-info">
                  <div class="name">{{ obj.name }}</div>
                  <div class="grade">
                    <span> 信用分: </span>
                    <span
                      style="font-size: 28px"
                      class="score_text"
                      v-if="item.grade >= 80"
                      :style="{
                        color: gradeColor[0],
                        borderColor: gradeColor[0],
                      }"
                    >
                      {{ Number(item.grade).toFixed(1) }}
                    </span>
                    <span
                      style="font-size: 28px"
                      class="score_text"
                      v-if="item.grade < 80 && item.grade >= 60"
                      :style="{
                        color: gradeColor[1],
                        borderColor: gradeColor[1],
                      }"
                    >
                      {{ Number(item.grade).toFixed(1) }}
                    </span>
                    <span
                      style="font-size: 28px"
                      class="score_text"
                      v-if="item.grade < 60 && item.grade >= 40"
                      :style="{
                        color: gradeColor[2],
                        borderColor: gradeColor[2],
                      }"
                    >
                      {{ Number(item.grade).toFixed(1) }}
                    </span>
                    <span
                      style="font-size: 28px"
                      class="score_text"
                      v-if="item.grade < 40"
                      :style="{
                        color: gradeColor[3],
                        borderColor: gradeColor[3],
                      }"
                    >
                      {{ Number(item.grade).toFixed(1) }}
                    </span>
                    <span>分</span>
                  </div>
                </div>
              </div>
              <div class="work-info">
                {{ obj.sex == '2' ? '女' : '男' }}
                <span>|</span>
                {{ obj.age }}岁
                <span>|</span>
                {{ obj.nation ? obj.nation : '汉' }}
                <span v-if="obj.edu">|</span>
                {{ obj.edu ? getPostEdu(obj.edu) : '' }}
              </div>
            </div>
            <div class="right">
              <!-- <div class="mask-box" v-if="isSee">
                  <div class="pop-up">
                    <div class="pop-info">
                      <div class="title-info">
                        使用个人权益<br />即可查看用户信用评价
                      </div>
                      <el-button type="primary" @click="clickEvaluate"
                        >查看评价</el-button
                      >
                    </div>
                  </div>
                </div> -->
              <h2 style="text-align: center">基本评价</h2>
              <biantu ref="biantu"></biantu>

              <h2 style="text-align: center; margin-top: 45px">信用评价</h2>
              <bian ref="bian"></bian>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="closeCreditEvaluation"
          >关闭</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      class="dialog1"
      width="900px"
      :visible.sync="outerVisible"
      custom-class="previewDialog"
      :modal-append-to-body="false"
    >
      <div class="create-info">
        <div class="user-info">
          <div class="logo-title">
            <img class="logo-img" src="@/assets/home/logo.png" alt="" />
            <div class="logo-text">甄工匠</div>
          </div>
          <div class="title-name">信用评价存证信息</div>
          <div class="left">
            <p class="user-name">{{ obj.name }}</p>
            <div class="info-data">
              {{ obj.sex == '2' ? '女' : '男' }}
              <span>|</span>
              {{ obj.age }}岁
              <span>|</span>
              {{ $occlusionCard(obj.idCard) }}
            </div>
          </div>
          <div class="right">
            <div class="grade-title">信用评价总得分</div>
            <p
              class="grade"
              v-if="item.grade >= 80"
              :style="{
                color: gradeColor[0],
                borderColor: gradeColor[0],
              }"
            >
              {{ Number(item.grade).toFixed(1) }}
            </p>
            <p
              class="grade"
              v-if="item.grade < 80 && item.grade >= 60"
              :style="{
                color: gradeColor[1],
                borderColor: gradeColor[1],
              }"
            >
              {{ Number(item.grade).toFixed(1) }}
            </p>
            <p
              class="grade"
              v-if="item.grade < 60 && item.grade >= 40"
              :style="{
                color: gradeColor[2],
                borderColor: gradeColor[2],
              }"
            >
              {{ Number(item.grade).toFixed(1) }}
            </p>
            <p
              class="grade"
              v-if="item.grade < 40"
              :style="{
                color: gradeColor[3],
                borderColor: gradeColor[3],
              }"
            >
              {{ Number(item.grade).toFixed(1) }}
            </p>
          </div>
        </div>
        <div class="detail-info">
          <div class="title-name">上链详情</div>
          <div v-for="(item, index) in data" :key="index" class="up-info">
            <div class="left">{{ item.name }}</div>
            <div class="right">{{ item.info }}</div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import biantu from '@/views/personalCenter/creditrating/biantu/biantu.vue' // 雷达图
import bian from '@/views/personalCenter/creditrating/biantu/bian.vue'
import {
  jobEvaluationDetail,
  getBCSGrad,
  getLoginUserInfo,
} from '@/api/evaluation/evaluation'
export default {
  name: '',
  data() {
    return {
      userId: '',
      orgId: '',
      gradeColor: ['#67CF61', '#3B94F7', '#e6a23c', '#ff0000'],
      srcImgUrl: require('../../assets/home/people.png'),
      data: [
        {
          name: '区块高度',
          info: '',
        },
        {
          name: '上链时间',
          info: '',
        },
        {
          name: '上链ID',
          info: '',
        },
        {
          name: '上链状态',
          info: '',
        },
      ],
      outerVisible: false,
      obj: {},
      item: {},
      item2: {
        mapType: {},
        mapTypeBase: {},
      },
      dict: [],
      orgType: [],
      jobEdu: [],
      cityList: [],
      jobExp: [],
      isSee: true,
      option: {
        color: ['#fc9a40'],
        radar: {
          indicator: [],
        },
        series: [
          {
            name: '往年评分 vs 当前评分',
            type: 'radar',
            data: [
              {
                value: [],
                name: '往年评分',
              },
            ],
          },
        ],
      },
      dataList: {
        color: ['#fc9a40'],
        radar: {
          indicator: [],
        },
        series: [
          {
            name: '往年评分 vs 当前评分',
            type: 'radar',
            data: [
              {
                value: [],
                name: '往年评分',
              },
            ],
          },
        ],
      },
      dialogVisible: false,
      tips: '',
      confirmType: '',
    }
  },
  watch: {},
  props: {
    creditEvaluationFlag: {
      type: Boolean,
      default: false,
    },
    idCard: {
      type: String,
      default: null,
    },
  },
  components: {
    biantu,
    bian,
  },
  computed: {},
  created() {
    this.loadData(this.idCard)
  },
  mounted() {},
  methods: {
    closeCreditEvaluation() {
      this.$emit('closeEvaluation', false)
    },
    loadData(idCard) {
      this.userId = localStorage.getItem('userId')
      this.orgId = localStorage.getItem('orgId')
      this.dict = JSON.parse(localStorage.getItem('dict'))
      this.orgType = this.dict.filter(i => i.code == 'job_org_type')[0].children
      this.jobEdu = this.dict.filter(i => i.code == 'job_edu')[0].children
      this.jobExp = this.dict.filter(i => i.code == 'job_exp')[0].children
      jobEvaluationDetail({ idCard, type: '1' }).then(res => {
        if (res && res.data && res.data.rows && res.data.rows.length > 0) {
          this.operationId = res.data.rows[0].id
          this.obj = res.data.rows[0]
          this.item = res.data.rows[0]
          if (res.data.rows[1]) {
            this.item2 = res.data.rows[1]
            this.item2.mapType =
              res.data.rows[1].mapType == null
                ? (res.data.rows[1].mapType = {})
                : res.data.rows[1].mapType
            this.item2.mapTypeBase =
              res.data.rows[1].mapTypeBase == null
                ? (res.data.rows[1].mapTypeBase = {})
                : res.data.rows[1].mapTypeBase
          }
          if (this.userId || this.orgId) {
            getLoginUserInfo().then(data => {
              if (data.data.idCard == idCard) {
                this.toSee()
              }
            })
          }
        }
      })
    },
    toWinding() {
      this.outerVisible = !this.outerVisible
      getBCSGrad({ idCard: this.idCard, userType: '1'})
        .then(res => {
          if (res.data) {
            let info = res.data
            info.Value = JSON.parse(info.Value)
            info.Timestamp = this.timestampToTime(info.Timestamp.slice(0, 10))
            info.BlockNum = info.BlockNum + 1
            this.data[0].info = info.BlockNum
            this.data[1].info = info.Timestamp
            this.data[2].info = info.TxHash
            this.data[3].info = info.IsDeleted ? 'error' : 'Success'
          } else {
            this.$message('用户暂无存证信息')
          }
        })
        .catch(error => {
          this.$message.error('用户暂无存证信息')
        })
    },
    timestampToTime(timestamp) {
      var date = new Date(timestamp * 1000) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + '-'
      var M =
        (date.getMonth() + 1 < 10
          ? '0' + (date.getMonth() + 1)
          : date.getMonth() + 1) + '-'
      var D = date.getDate() + ' '
      var h = date.getHours() + ':'
      var m = date.getMinutes() + ':'
      var s = date.getSeconds()
      return Y + M + D
    },
    toSee() {
      this.isSee = false
      this.mapTypeInfo()
      this.mapTypeBaseInfo()
      var that = this
      setTimeout(() => {
        that.$refs.biantu.budil(this.dataList)
        that.$refs.bian.budil(this.option)
      }, 500)
    },
    mapTypeInfo() {
      this.option.radar.indicator = []
      this.option.series[0].data[0].value = []
      let keys1 = Object.keys(this.item.mapType)
      let values1 = Object.values(this.item.mapType)
      values1.map((item, index) => {
        let obj = {
          name: keys1[index],
          max: item[0],
        }
        this.option.radar.indicator.push(obj)
        this.option.series[0].data[0].value[index] = item[1]
      })
    },
    mapTypeBaseInfo() {
      this.dataList.radar.indicator = []
      this.dataList.series[0].data[0].value = []
      let keys1 = Object.keys(this.item.mapTypeBase)
      let values1 = Object.values(this.item.mapTypeBase)
      values1.map((item, index) => {
        let obj = {
          name: keys1[index],
          max: item[0],
        }
        this.dataList.radar.indicator.push(obj)
        this.dataList.series[0].data[0].value[index] = item[1]
      })
    },

    getOrgType(id) {
      return this.orgTyp.length > 0
        ? this.orgType.filter(i => i.code == id)[0].name
        : ''
    },
    getPostEdu(id) {
      return this.jobEdu.length > 0
        ? this.jobEdu.filter(i => i.code == id)[0].name
        : ''
    },
    getPostExp(id) {
      return this.jobExp.length > 0
        ? this.jobExp.filter(i => i.code == id)[0].name
        : ''
    },
    geTel(tel) {
      var reg = /^(\d{3})\d{4}(\d{4})$/
      return tel.replace(reg, '$1****$2')
    },
    handleResize() {
      this.fullWidth = document.documentElement.clientWidth
      this.fullHeight = document.documentElement.clientHeight
    },
  },
  filters: {},
}
</script>

<style scoped lang="scss">
.creditEvaluationBox {
  height: 63vh;
  overflow: auto;
}
.creditEvaluationBox::-webkit-scrollbar {
  width: 4px;
}

.creditEvaluationBox::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
}

::v-deep .dialog1 .el-dialog__header {
  display: none;
  padding: 0 !important;
}
::v-deep .dialog1 .el-dialog {
  background-color: rgba(254, 254, 254, 0);
  box-shadow: none;
}
::v-deep .dialog1 .el-dialog__body {
  padding: 0 !important;
  background-image: url('../../assets/course/up-img.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.previewDialog {
  .create-info {
    display: flex;
  }

  .user-info {
    width: 28%;
    align-items: center;
    padding: 30px 30px;

    .logo-title {
      text-align: center;
      .logo-img {
        width: 47px;
        height: 53px;
      }
      .logo-text {
        color: #0d9ff9;
        font-size: 20px;
        font-weight: bold;
      }
    }
    .title-name {
      padding: 10px 20px;
      margin-top: 20px;
      font-size: 18px;
      text-align: center;
      font-weight: bold;
    }
    .user-name {
      font-size: 18px;
      font-weight: bold;
    }
    .left {
      .info-data {
        margin: 20px 0;
      }
    }
    .right {
      overflow: hidden;
      align-items: center;
      display: flex;
      .grade-title {
        font-size: 18px;
        margin-right: 20px;
      }
      .grade {
        color: #ff5a47;
        font-size: 28px;
        font-family: buer;
      }
    }
  }
  .detail-info {
    width: 55%;
    padding: 15px 0;
    margin-left: 40px;
    .title-name {
      padding: 10px 0;
      font-size: 18px;
      font-weight: bold;
    }
    .up-info {
      display: flex;
      justify-content: space-around;
      box-sizing: border-box;
      padding: 10px;
      margin: 7px 0;

      .left {
        width: 20%;
        font-size: 16px;
      }
      .right {
        flex: 1;
        width: 50%;
        font-size: 16px;
      }
    }
  }
}
.credit-evi {
  margin: 0 auto;
  margin-bottom: 20px;
  .evi-box {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 145px;
    height: 40px;
    line-height: 40px;
    background-color: #029aff;
    color: #fff;
    font-size: 14px;
    border-radius: 7px;
    text-align: center;
    margin: auto 0px auto auto;
    .solid {
      margin: 0 18px;
      color: #fff;
    }
  }
}
.info-title {
  text-align: center;
  font-size: 36px;
  font-weight: 500;
  margin-bottom: 30px;
}
.itemName {
  padding: 0 86px;
  padding-bottom: 22px;
  .dividerFont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 50px;
    span {
      font-size: 24px;
      margin-bottom: 6px;
    }
  }
}

.creditrating-box {
  background-image: url('~@/assets/course/border-img.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 100px;
  span {
    color: rgba(51, 51, 51, 1);
    font-size: 22px;
  }
  .title {
    position: relative;
    .mes {
      font-size: 25px;
      position: absolute;
      margin-left: 20px;
    }
  }
}

.creditrating-min {
  display: flex;
  justify-content: space-around;
  .left {
    width: 500px;
    color: rgba(51, 51, 51, 1);
    font-size: 18px;
    line-height: 36px;
    margin-top: 50px;
    .left-head {
      display: flex;
      justify-content: center;
      // align-items:center;
      .img {
        width: 120px;
        height: 120px;
        object-fit: cover;
      }
      .head-info {
        margin-left: 42px;
        .name {
          font-size: 28px;
          font-weight: bold;
          margin-bottom: 50px;
        }
        .grade {
          font-size: 20px;
        }
      }
    }
    .work-info,
    .work {
      margin-top: 10px;
      margin-left: 65px;
    }
  }
  .right {
    width: 500px;
    color: rgba(51, 51, 51, 1);
    font-size: 18px;
    line-height: 36px;
    position: relative;
    .mask-box {
      position: absolute;
      width: 500px;
      height: 100%;
      backdrop-filter: blur(8px);
      z-index: 3;
      .pop-up {
        width: 351px;
        height: 246px;
        background-image: url('~@/assets/course/pop-img.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        margin: 50% auto;
        border-radius: 3px;
        overflow: hidden;
        .pop-info {
          width: 250px;
          height: 170px;
          background-color: #fff;
          margin: 38px auto;
          border-radius: 14px;
          text-align: center;
          font-size: 14px;
          overflow: hidden;
          .title-info {
            margin: 20px;
          }
        }
      }
    }
  }
}

::v-deep {
  .el-pagination.is-background .btn-prev {
    /*对下一页的按钮样式进行修改*/
    background: #fff;
  }
  .el-pagination.is-background .btn-next {
    /*对上一页的按钮样式进行修改*/
    background: #fff;
  }
  .el-pagination.is-background .el-pager li:not(.disabled) {
    background-color: #fff; // 进行修改未选中背景和字体
    // color: #fff;
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: rgb(19, 138, 206); // 进行修改选中项背景和字体
    color: rgb(255, 255, 255);
  }
}
</style>
